// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import ContactList from './ContactList';
import ContactDetail from './ContactDetail';
import RegisterContact from './RegisterContact';
import Ticket from './Ticket';
import EventDay from './EventDay';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TrainingRegistration from './TrainingRegistration';

const App = () => {
  return (
    <div className="App">
      <HelmetProvider>
      <Routes>
        <Route path="/contact-detail/:id" element={<ContactDetail />} />
        <Route path='choose_day' element={<EventDay/>}/>
        <Route path="/attendes" element={<ContactList />} />
        <Route path="/training" element={<TrainingRegistration />} />
        <Route path="/ticket/:email" element={<Ticket />} />
        <Route path="/contact/:email" element={<ContactDetail />} />
        <Route path="/" element={<RegisterContact />} />
      </Routes>
      </HelmetProvider>
    </div>
  );
};

export default App;
