import React, { useState } from 'react';
import './CalenderForm.css'; // Import the CSS file
import brevoService from './brevoService'; // Import the Brevo service
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function CheckboxForm() {
  const [selectedDays, setSelectedDays] = useState({
    day1: false,
    day2: false,
    day3: false
  });

  const [email, setEmail] = useState('');

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      [name]: checked
    }));
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const selectedListIds = {
      day1: 112, // Replace with the actual list ID for Day 1
      day2: 113, // Replace with the actual list ID for Day 2
      day3: 114  // Replace with the actual list ID for Day 3
    };

    const selectedLists = Object.keys(selectedDays)
      .filter((day) => selectedDays[day])
      .map((day) => selectedListIds[day]);

    const contact = {
      email: email, // Use the email from the form
    };

    try {
      for (const listId of selectedLists) {
        await brevoService.createContact({ ...contact, listIds: [listId] });
      }
      console.log('Contact successfully added to selected lists');
    } catch (error) {
      console.error('Error adding contact to lists:', error);
    }

    // Reset the form after submission if needed
    setSelectedDays({
      day1: false,
      day2: false,
      day3: false
    });
    setEmail('');
  };

  return (
    <div className="calendar-container">
         <Helmet>
        <title>Calendar</title>
      </Helmet>
      <div className="calendar-header">
        <h1>Register for Available Days</h1>
        <p>Select the days you want to attend and provide your email address</p>
      </div>
      <form onSubmit={handleSubmit} className="calendar-form">
        <div className="calendar-item">
          <label className="calendar-label">Day 1 Tuesday 9 July</label>
          <input
            type="checkbox"
            name="day1"
            checked={selectedDays.day1}
            onChange={handleCheckboxChange}
            className="calendar-checkbox"
          />
        </div>
        <div className="calendar-item">
          <label className="calendar-label">Day 2 Wednesday 10 July</label>
          <input
            type="checkbox"
            name="day2"
            checked={selectedDays.day2}
            onChange={handleCheckboxChange}
            className="calendar-checkbox"
          />
        </div>
        <div className="calendar-item">
          <label className="calendar-label">Day 3 Thursday 11 July</label>
          <input
            type="checkbox"
            name="day3"
            checked={selectedDays.day3}
            onChange={handleCheckboxChange}
            className="calendar-checkbox"
          />
        </div>
        <div className="calendar-item">
          <label className="calendar-label">Email Address</label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            className="calendar-input"
            required
          />
        </div>
        <button type="submit" className="calendar-submit">Submit</button>
      </form>
    </div>
  );
}
