import axios from 'axios';

const API_KEY = "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";
const BASE_URL = 'https://api.sendinblue.com/v3';

const brevoService = {
  getContactsFromList: async (listId) => {
    try {
      // Check if the listId is in the allowed list
      if (![108, 110].includes(listId)) {
        throw new Error(`List ${listId} is not allowed.`);
      }

      let allContacts = [];
      let offset = 0;
      const limit = 50; // Brevo API default limit is usually 50, adjust as needed

      while (true) {
        const response = await axios.get(`${BASE_URL}/contacts/lists/${listId}/contacts`, {
          headers: {
            'api-key': API_KEY,
            'Content-Type': 'application/json'
          },
          params: {
            limit,
            offset
          }
        });

        const contacts = response.data.contacts;
        if (contacts.length === 0) break;

        allContacts = allContacts.concat(contacts);
        offset += limit;
      }

      return allContacts;
    } catch (error) {
      console.error(`Error fetching contacts from list ${listId}:`, error);
      throw error;
    }
  },

  getContactList: async () => {
    try {
      const listIds = [108, 110];
      const contactPromises = listIds.map(id => brevoService.getContactsFromList(id));
      const contactsFromLists = await Promise.all(contactPromises);
      
      // Combine contacts from all lists
      const combinedContacts = contactsFromLists.flat();
      return combinedContacts;
    } catch (error) {
      console.error('Error fetching contacts:', error);
      throw error;
    }
  },

  createContact: async (contact) => {
    try {
      const contactData = {
        email: contact.email,
        attributes: {
          NAME: contact.name,
          ID_NUMBER: contact.id_number,
          COMPANY_NAME: contact.company,
          COUNTRY_OF_ORIGIN: contact.country,
          PHONE: contact.phone,
          WHERE_DID_YOU_HEAR_ABOUT_US: contact.where,
          //EMAIL: contact.email
        },
        listIds: contact.listIds, // Pass the dynamic listIds here
        updateEnabled: true
      };
  
      const response = await axios.post(`${BASE_URL}/contacts`, contactData, {
        headers: {
          'api-key': API_KEY,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error creating contact:', error);
      throw error;
    }
  },
  
  sendEmail: async (emailData) => {
    try {
      const response = await axios.post(`${BASE_URL}/smtp/email`, emailData, {
        headers: {
          'api-key': API_KEY,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error sending email:', error);
      throw error;
    }
  }
};

export default brevoService;
