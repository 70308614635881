import html2canvas from 'html2canvas';

const patchedHtml2canvas = (element, options = {}) => {
    return html2canvas(element, {
        ...options,
        onclone: (clonedDoc) => {
            if (options.onclone) {
                options.onclone(clonedDoc);
            }
            const canvases = clonedDoc.querySelectorAll('canvas');
            canvases.forEach((canvas) => {
                const ctx = canvas.getContext('2d', { willReadFrequently: true });
                // Override the getContext method to always use willReadFrequently
                canvas.getContext = (type) => {
                    if (type === '2d') {
                        return ctx;
                    }
                    return canvas.getContext(type);
                };
            });
        },
    });
};

export default patchedHtml2canvas;
