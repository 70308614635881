import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import jsPDF from 'jspdf';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import './ticket.css';
import Diplomat from './logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faParking, faToilet, faBowlFood } from '@fortawesome/free-solid-svg-icons';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Ticket = () => {
  const { email } = useParams();
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const ticketRef = useRef();

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const response = await axios.get('https://api.sendinblue.com/v3/contacts/' + email, {
          headers: {
            'api-key': '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',
            'Content-Type': 'application/json'
          }
        });
        setContact(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchContact();
  }, [email]);

  const generatePDF = async () => {
    const ticketElement = ticketRef.current;
    const canvas = await html2canvas(ticketElement, { scale: 1.5 });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    return pdf;
  };

  const downloadPDF = async () => {
    setDownloading(true);
    try {
      const pdf = await generatePDF();
      pdf.save('Registration Details.pdf');
    } catch (error) {
      console.error('Error downloading PDF:', error);
      alert('Failed to download PDF');
    } finally {
      setDownloading(false);
    }
  };

  const sendPDFByEmail = async () => {
    setSendingEmail(true);
    try {
      const pdf = await generatePDF();
      const pdfData = pdf.output('datauristring').split(',')[1];
      const response = await axios.post(
        'https://api.sendinblue.com/v3/smtp/email',
        {
          sender: { email: 'info@secures.diplomat.co.sz' },
          to: [{ email: contact.email }],
          subject: 'Ticket Registration info',
          htmlContent: ` <div style="font-family: Arial, sans-serif; border: 1px solid red; padding: 20px; max-width: 600px; margin: auto;">
          <h2 style="text-align: center; color: #333;">Ticket Registration Details</h2>
          <p style="font-size: 16px; color: #555;">
            Please find the ticket details attached as a PDF.
          </p>
          <p style="font-size: 16px; color: #555;">
            If you have any questions, feel free to contact us at <a href="mailto:info@secures.diplomat.co.sz" style="color: #007BFF;">info@secures.diplomat.co.sz</a>.
          </p>
          <div style="text-align: center; margin-top: 20px;">
            <a href="mailto:info@secures.diplomat.co.sz" style="text-decoration: none; color: #FFF; background-color: red; padding: 10px 20px; border-radius: 5px;">Contact Us</a>
          </div>
          <div style="text-align: center; margin-top: 20px;">
            <img src="https://photos.app.goo.gl/eRF1YUzKkTBKK3Ak9" alt="Company Logo" style="max-width: 150px;"/>
          </div>
        </div>`,
          attachment: [
            {
              content: pdfData,
              name: 'Registration_Details.pdf'
            }
          ]
        },
        {
          headers: {
            'api-key': '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',
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 201) {
        alert('Ticket sent successfully');
      } else {
        alert('Ticket to send PDF');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send ticket');
    } finally {
      setSendingEmail(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!contact) return <div>No contact found</div>;

  return (
    <div className="ticket-container">
      <Helmet>
        <title>Ticket</title>
      </Helmet>
      <div className="ticket-content-wrapper">
        {downloading && <div className="loading">Downloading PDF...</div>}
        {sendingEmail && <div className="loading">Sending Email...</div>}
        <div className="ticket" ref={ticketRef}>
          <img src={Diplomat} alt="Diplomat Logo"/>
          <div className="ticket-header"><br/><br/>
            <h1>Diplomat SecurES Expo</h1>
          </div>
          <div className="ticket-body">
            <p><strong>NAME:</strong> {contact.attributes.NAME}</p>
            <p><strong>EMAIL:</strong> {contact.email}</p>
            <p><strong>COMPANY NAME:</strong> {contact.attributes.COMPANY_NAME}</p>
            <div className="qr-code">
              <p><strong>9 - 11 JULY | 9AM TO 4PM</strong></p>
              <QRCode value={contact.email} />
            </div>
          </div>
          <div className='ticket-footer'>
            <div className="icons">
              <div className="icon">
                <FontAwesomeIcon icon={faParking} size="2x" />
                <span>Parking Access</span>
              </div>
              <div className="icon">
                <FontAwesomeIcon icon={faToilet} size="2x" />
                <span>Lavatory Access</span>
              </div>
              <div className='icon'>
                <FontAwesomeIcon icon={faBowlFood} size="2x" />
                <span>Food Access</span>
              </div>
            </div>
            <br/><br/><br/>
            <p>
              Location: Swazi Plaza Roof Top Parking<br/>
              https://secures.diplomat.co.sz
            </p>
          </div>
        </div>
        <div className="button-container">
          <button onClick={downloadPDF} disabled={downloading || sendingEmail}>
            {downloading ? 'Downloading...' : 'Download as PDF'}
          </button>
          <a href='https://secures.diplomat.co.sz'>
            <button>Home</button>
          </a>
          <button onClick={sendPDFByEmail} disabled={downloading || sendingEmail}>
            {sendingEmail ? 'Sending Email...' : 'Send PDF by Email'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
