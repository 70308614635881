import React, { useEffect, useState, useRef } from 'react';
import brevoService from './brevoService';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import jsPDF from 'jspdf';
import patchedHtml2canvas from './patchedHtml2canvas'; // Import the patched version
import axios from 'axios';
import './ContactList.css';

const ContactDetail = () => {
  const { email } = useParams();
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const ticketRef = useRef();

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const contacts = await brevoService.getContactList();
        const contact = contacts.find(contact => contact.email === email);
        setContact(contact);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchContact();
  }, [email]);

  const generatePDF = async () => {
    const ticketElement = ticketRef.current;
    const canvas = await patchedHtml2canvas(ticketElement); // Use the patched version
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4'
    });
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    return pdf; // Return jsPDF instance
  };

  const downloadPDF = async () => {
    const pdf = await generatePDF();
    pdf.save('contact_details.pdf'); // Trigger download
  };

  const sendPDFByEmail = async () => {
    try {
      const pdf = await generatePDF();
      const pdfData = pdf.output('datauristring').split(',')[1]; // Get base64 PDF string
      const response = await axios.post(
        'https://api.sendinblue.com/v3/smtp/email',
        {
          sender: { email: 'chiremba.tonderai@diplomat.co.sz' }, // Replace with your email
          to: [{ email: contact.email }], // Send to the contact's email
          subject: 'Contact Details PDF',
          htmlContent: '<p>Please find the contact details attached as a PDF.</p>',
          attachment: [
            {
              content: pdfData,
              name: 'contact_details.pdf'
            }
          ]
        },
        {
          headers: {
            'api-key': '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', // Replace with your Brevo API key
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 201) {
        alert('PDF sent successfully');
      } else {
        alert('Failed to send PDF');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send PDF');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!contact) return <div>No contact found</div>;

  return (
    <div>
      <div className="ticket" ref={ticketRef}>
        <div className="ticket-header">
          <h1>Contact Details</h1>
        </div>
        <div className="ticket-body">
          <p><strong>Email:</strong> {contact.email}</p>
          <p><strong>Name:</strong> {contact.attributes.NAME}</p>
          <p><strong>Company Name:</strong> {contact.attributes.COMPANY_NAME}</p>
          <div className="qr-code">
            <QRCode value={contact.email} />
          </div>
        </div>
      </div>
      <button onClick={downloadPDF}>Download as PDF</button>
      <button onClick={sendPDFByEmail}>Send PDF by Email</button>
    </div>
  );
};

export default ContactDetail;
