import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './RegisterContact.css'; // Import your CSS file
import Logo from './logo.png'
import Diplomat from './diplomat.png'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const TrainingRegistration = () => {
  const [formData, setFormData] = useState({
    name: '',
    id_number: '',
    company: '',
    country: '',
    phone: '',
    where: '',
    email: '',
  });

  const navigate = useNavigate();
  const API_KEY = "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";
  const BASE_URL = 'https://api.sendinblue.com/v3';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const contactData = await handleCreateContact(formData);
      generatePdf();
      toast.success('Contact created successfully');
      navigate(`/ticket/${formData.email}`); // Navigate to the contact detail page
    } catch (error) {
      console.error('Error creating contact:', error);
      toast.error('Failed to create contact');
    }
  };

  const handleCreateContact = async (formData) => {
    try {
      const contactData = {
        email: formData.email,
        attributes: {
          NAME: formData.name,
          ID_NUMBER: formData.id_number,
          COMPANY_NAME: formData.company,
          COUNTRY_OF_ORIGIN: formData.country,
          PHONE: formData.phone,
          WHERE_DID_YOU_HEAR_ABOUT_US: formData.where,
        },
        listIds: [110], // Replace with your list ID if necessary
        updateEnabled: true,
      };

      const response = await axios.post(`${BASE_URL}/contacts`, contactData, {
        headers: {
          'api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      });
      return response.data; // Return the created contact data
    } catch (error) {
      console.error('Error creating contact:', error);
      throw error;
    }
  };

  const generatePdf = () => {
    const input = document.getElementById('registration-details');
    html2canvas(input)
      .then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 10, 10);
        pdf.save('registration-details.pdf');
      })
      .catch(error => {
        console.error('Error generating PDF:', error);
        toast.error('Failed to generate PDF');
      });
  };

  return (
    <div className="container">
        <Helmet>
        <title>Registration</title>
      </Helmet>
      <div className="content-wrapper">
        <div className="image-section">
          <a href='https://secures.diplomat.co.sz'><img src={Logo} alt="Description" /></a>
          <h1>About The Expo >> </h1>
          <p>
          Welcome to the registration page for SecurES Security Expo 2024, Eswatini's premier event dedicated to showcasing the latest innovations in security
           and safety. Join us from July 9th to 11th at Swazi Plaza for a transformative experience featuring cutting-edge technologies, expert-led 
           workshops, and unparalleled networking opportunities with industry leaders and professionals. Don't miss out on this unique chance to explore the
            future of security. Register now to secure your spot and be a part of a safer tomorrow!
          </p>
          <p>~Hosted By ~</p>
          <p> <img src={Diplomat} alt='logo'/></p>
        </div>
        <div className="form-section">
          <h1>Training Registration</h1><button className="button" onClick={()=> navigate('/')}>Exibitor Registration</button>
          <form className="form" onSubmit={handleSubmit}>
            <div>
              <label>Name:</label>
              <input required type="text" name="name" value={formData.name} onChange={handleChange} />
            </div>
            {/* <div>
              <label>ID Number:</label>
              <input required type="text" name="id_number" value={formData.id_number} onChange={handleChange} />
            </div> */}
            <div>
              <label>Company:</label>
              <input required type="text" name="company" value={formData.company} onChange={handleChange} />
            </div>
            <div>
              <label>Country of Origin:</label>
              <input required type="text" name="country" value={formData.country} onChange={handleChange} />
            </div>
            <div>
              <label>Phone:</label>
              <input value={formData.phone} onChange={handleChange} name="phone" type="text" required />
            </div>
            <div>
              <label>Where did you hear about the Expo:</label>
              <input value={formData.where} onChange={handleChange} name="where" type="text" required />
            </div>
            <div>
              <label>Email:</label>
              <input value={formData.email} onChange={handleChange} name="email" type="email" required />
            </div>
            <div className="button-container">
              <button type="submit">Register</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TrainingRegistration;
