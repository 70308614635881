import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react'; // Import QRCode component for display
import brevoService from './brevoService';
import './ContactList.css';
import html2canvas from 'html2canvas';
import axios from 'axios';
import QrScanner from 'react-qr-scanner';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import QRCodeGenerator from 'qrcode'; // Import QRCode library for generating QR codes
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Logo from "./SecuriLogo.png";

const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [validationResult, setValidationResult] = useState('');
  const [isScanningEmail, setIsScanningEmail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const data = await brevoService.getContactList();
        setContacts(data); // Store the full contact list
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchContacts();
  }, [isScanningEmail]);

  const handleScan = (result) => {
    if (result) {
      const email = result.text;
      const contact = contacts.find(c => c.email === email); // Use allContacts for validation

      if (contact) {
        setValidationResult('Valid');
      } else {
        setValidationResult('Invalid');
      }
      setTimeout(() => resetScanner(), 2000); // Reset scanner after 2 seconds
    }
  };

  const handleError = (err) => {
    console.error(err);
    setScanning(false);
  };

  const resetScanner = () => {
    setScanning(false);
    setValidationResult('');
    setIsScanningEmail(false);
    setTimeout(() => setScanning(true), 100); // Slight delay to reinitialize scanner
  };

  const generatePDF = async (contact) => {
    const pdf = new jsPDF('p', 'pt', 'a4');

    // Set red theme colors
    const primaryColor = '#FF0000'; // Red for headings and lines
    const gradientColor1 = '#FFC7C7'; // Light red gradient background
    const gradientColor2 = '#FFFFFF'; // White gradient background

    // Set font styles
    pdf.setFont('helvetica', 'normal');

    // Header with logos and centered table for "SecurES Expo"
    const logo = Logo;
    const logoWidth = 80;
    const logoHeight = 50

    // Left logo
    pdf.addImage(logo, 'PNG', 40, 20, logoWidth, logoHeight);
    // Right logo
    pdf.addImage(logo, 'PNG', pdf.internal.pageSize.getWidth() - 120, 20, logoWidth, logoHeight);

    let yOffset = 100;

    // Contact details table
    const contactDetails = [
      ['Name', contact.attributes?.NAME || 'N/A'],
      ['Company', contact.attributes?.COMPANY_NAME || 'N/A'],
      ['Email', contact.email || 'N/A'],
      ['Country', contact.attributes?.COUNTRY || 'N/A'],
    ];

    autoTable(pdf, {
      head: [['','securES Expo 2024']],
      body: contactDetails,
      startY: yOffset,
      styles: { cellPadding: 3, fontSize: 12, fillColor: primaryColor, textColor: [255, 255, 255] },
      theme: 'grid'
    });

    yOffset = pdf.autoTable.previous.finalY + 10;

    // Generate QR code for email
    const qrCodeDataURL = await QRCodeGenerator.toDataURL(contact.email, { width: 128 });

    // Center QR code and label
    const qrCodeWidth = 80;
    const qrCodeX = (pdf.internal.pageSize.getWidth() - qrCodeWidth) / 2;
    pdf.addImage(qrCodeDataURL, 'PNG', qrCodeX, yOffset, qrCodeWidth, qrCodeWidth);
    pdf.setTextColor(primaryColor);
    pdf.setFontSize(12);
    const qrCodeLabelWidth = pdf.getStringUnitWidth('Scan to email') * 12 / pdf.internal.scaleFactor;
    const qrCodeLabelX = (pdf.internal.pageSize.getWidth() - qrCodeLabelWidth) / 2;
    yOffset += qrCodeWidth + 20;

    // Footer with centered text and adjusted margins
    pdf.setTextColor(primaryColor);
    pdf.setFontSize(10);
    const footerTextWidth = pdf.getStringUnitWidth('Generated by Your Company') * 10 / pdf.internal.scaleFactor;
    const footerTextX = (pdf.internal.pageSize.getWidth() - footerTextWidth) / 2;
    pdf.text('securES Expo', footerTextX, pdf.internal.pageSize.getHeight() - 20, { align: 'center' });

    return pdf;
  };


  const downloadPDF = async (contact) => {
    const pdf = await generatePDF(contact);
    pdf.save('Registration details.pdf');
  };

  const sendPDFByEmail = async (contact, pdfData) => {
    const emailData = {
      sender: { email: 'info@secures.diplomat.co.sz' },
      to: [{ email: contact.email }],
      subject: 'Ticket Registration Info',
      htmlContent: `<div style="font-family: Arial, sans-serif; border: 1px solid red; padding: 20px; max-width: 600px; margin: auto;">
      <h2 style="text-align: center; color: #333;">Ticket Registration Details</h2>
      <p style="font-size: 16px; color: #555;">
        Please find the ticket details attached as a PDF.
      </p>
      <p style="font-size: 16px; color: #555;">
        If you have any questions, feel free to contact us at <a href="mailto:info@secures.diplomat.co.sz" style="color: #007BFF;">info@secures.diplomat.co.sz</a>.
      </p>
      <div style="text-align: center; margin-top: 20px;">
        <a href="mailto:info@secures.diplomat.co.sz" style="text-decoration: none; color: #FFF; background-color: red; padding: 10px 20px; border-radius: 5px;">Contact Us</a>
      </div>
      <div style="text-align: center; margin-top: 20px;">
        <img src="https://photos.app.goo.gl/eRF1YUzKkTBKK3Ak9" alt="Company Logo" style="max-width: 150px;"/>
      </div>
    </div>`,
      attachment: [
        {
          content: pdfData,
          name: 'Ticket details.pdf',
        },
      ],
    };

    try {
      const response = await axios.post(
        'https://api.sendinblue.com/v3/smtp/email',
        emailData,
        {
          headers: {
            'api-key': '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',
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        console.log(`PDF sent successfully to ${contact.email}`);
      } else {
        console.error(`Failed to send PDF to ${contact.email}`);
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const sendBulkEmails = async () => {
    for (const contact of contacts) { // Use allContacts for bulk email
      const pdf = await generatePDF(contact);
      await sendPDFByEmail(contact, pdf.output('datauristring').split(',')[1]);
    }
    alert('Bulk emails sent successfully');
  };

  const downloadAllPDFs = async () => {
    const zip = new JSZip();
    for (const contact of contacts) {
      const pdf = await generatePDF(contact);
      const pdfBlob = pdf.output('blob'); // Get the PDF blob
      zip.file(`${contact.email}_Ticket.pdf`, pdfBlob);
    }
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'All_Tickets.zip');
  };

  if (loading) return <div className="loader">Loading...</div>;
  if (error) return <div className="error">Error: {error.message}</div>;

  const previewStyle = {
    height: 240,
    width: 320,
  };

  const constraints = {
    video: {
      facingMode: 'environment' // Use rear camera
    }
  };

  return (
    <div className="contact-list-container">
      <h1>SecurES Attendees</h1>
      <p>Number of participants: {contacts.length}</p>
      <button className='button' onClick={() => {
        setScanning(true);
        setIsScanningEmail(true);
      }}>Scan QR Code</button>
      <button className='button' onClick={sendBulkEmails}>Send Tickets</button>
      <button className='button' onClick={downloadAllPDFs}>Download All Tickets</button>
      {scanning && (
        <QrScanner
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={previewStyle}
          constraints={constraints}
        />
      )}
      {validationResult && (
        <div className={`valid-message ${validationResult === 'Valid' ? 'valid' : 'invalid'}`}>
          {validationResult}
        </div>
      )}
      <ul className="contact-list">
        {contacts.map(contact => (
          <li key={contact.id} className="contact-item">
            <div className="qr-code">
              <QRCode value={contact.email} size={128} />
            </div>
            <div className="contact-info">
              <div className="contact-email">{contact.email}</div>
              <div className="contact-name">{contact.attributes.NAME} {contact.attributes.COMPANY}</div>
            </div>
            <div className="buttons">
              <button className="button" onClick={() => downloadPDF(contact)}>Download PDF</button>
              <button className="button" onClick={() => sendPDFByEmail(contact)}>Send PDF by Email</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContactList;
